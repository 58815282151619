import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import {
  Share2,
  Bookmark,
  BookmarkPlus,
  ChevronUp,
  Calendar,
  Clock,
  Tag,
  AlertTriangle,
  Battery,
  Sun,
  Zap,
  MessageCircle,
  ThumbsUp,
  ThumbsDown,
  List,
  ChevronRight,
  ArrowRight
} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const LoadSheddingSouthAfrica2025 = () => {
  const { isDarkMode } = useTheme();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('');
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [feedback, setFeedback] = useState({ helpful: 0, notHelpful: 0 });
  
  const articleRef = useRef(null);
  const sectionsRef = useRef({});

  const publishDate = "2025-01-31T09:00:00Z";
  const siteUrl = 'https://solarinsight.co.za';
  const articlePath = '/news/load-shedding-south-africa-2025';
  const fullUrl = `${siteUrl}${articlePath}`;

  // Reading progress calculation
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);
      setShowScrollTop(window.scrollY > 300);

      // Update active section
      Object.entries(sectionsRef.current).forEach(([id, ref]) => {
        if (ref && ref.getBoundingClientRect().top < window.innerHeight / 3) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId) => {
    sectionsRef.current[sectionId]?.scrollIntoView({ behavior: 'smooth' });
  };

  const loadSheddingData = [
    { stage: 'Stage 1', hours: 2.5 },
    { stage: 'Stage 2', hours: 5 },
    { stage: 'Stage 3', hours: 7.5 },
    { stage: 'Stage 4', hours: 10 },
    { stage: 'Stage 5', hours: 12 },
  ];

  const relatedArticles = [
    {
      title: 'New Perovskite Solar Cells Break Efficiency Record',
      url: '/news/perovskite-solar-breakthrough-2025',
      date: '2025-01-29',
    },
    {
      title: 'Solar Power Solutions for Load Shedding',
      url: '/insights/solar-solutions-load-shedding',
      date: '2025-01-25',
    },
    {
      title: 'Government Announces New Energy Policy',
      url: '/news/energy-policy-update-2025',
      date: '2025-01-20',
    },
  ];

  const tableOfContents = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'current-state', title: 'Current State in 2025' },
    { id: 'root-causes', title: 'Root Causes' },
    { id: 'socio-economic-impact', title: 'Socio-Economic Impact' },
    { id: 'responses', title: 'Government and Private Sector Responses' },
    { id: 'path-forward', title: 'Path Forward' },
    { id: 'conclusion', title: 'Conclusion' },
  ];

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Load Shedding in South Africa 2025',
          text: 'An in-depth analysis of the ongoing energy crisis and solutions.',
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": "Load Shedding in South Africa 2025: Crisis and Solutions",
    "datePublished": publishDate,
    "dateModified": publishDate,
    "author": {
      "@type": "Organization",
      "name": "Solar Insight"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}/logo.png`
      }
    },
    "description": "A comprehensive analysis of South Africa's load shedding crisis in 2025, examining causes, impacts, and sustainable solutions.",
    "keywords": "load shedding, South Africa, energy crisis, Eskom, renewable energy, solar power, 2025",
    "articleSection": "Energy News",
    "image": `${siteUrl}/images/load-shedding-2025.jpg`,
    "url": fullUrl,
    "mainEntityOfPage": fullUrl
  };

  const keyImpacts = [
    {
      icon: <AlertTriangle className="h-6 w-6" />,
      title: "Economic Impact",
      description: "Daily losses of R500 million affecting GDP growth"
    },
    {
      icon: <Battery className="h-6 w-6" />,
      title: "Power Outages",
      description: "Up to 8 hours of daily blackouts in affected areas"
    },
    {
      icon: <Sun className="h-6 w-6" />,
      title: "Renewable Shift",
      description: "30% renewable energy target by 2030"
    },
    {
      icon: <Zap className="h-6 w-6" />,
      title: "Grid Stability",
      description: "Aging infrastructure requiring urgent upgrades"
    }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Load Shedding in South Africa 2025: Crisis and Solutions | Solar Insight</title>
        <meta name="description" content="Comprehensive analysis of South Africa's load shedding crisis in 2025, examining root causes, impacts, and sustainable solutions for the future." />
        <meta name="keywords" content="load shedding, South Africa, energy crisis, Eskom, renewable energy, solar power, 2025" />
        
        <meta property="og:title" content="Load Shedding in South Africa 2025: Crisis and Solutions" />
        <meta property="og:description" content="In-depth analysis of South Africa's energy crisis and the path to sustainable solutions." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:image" content={`${siteUrl}/images/load-shedding-2025.jpg`} />
        
        <link rel="canonical" href={fullUrl} />
        
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-1 bg-gray-200 z-50"
        style={{ 
          background: isDarkMode ? 'rgba(30, 58, 138, 0.3)' : 'rgba(251, 191, 36, 0.3)'
        }}
      >
        <div
          className={`h-full transition-all duration-300 ${
            isDarkMode ? 'bg-yellow-400' : 'bg-amber-500'
          }`}
          style={{ width: `${readingProgress}%` }}
        />
      </div>

      <main className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto" ref={articleRef}>
          {/* Hero Section */}
          <section className={`rounded-xl shadow-2xl mb-12 p-8 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-800 to-blue-900' 
              : 'bg-gradient-to-br from-amber-100 to-amber-200'
          }`}>
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 text-center ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Load Shedding in South Africa 2025
            </h1>
            <p className={`text-xl mb-8 text-center ${
              isDarkMode ? 'text-yellow-100' : 'text-amber-800'
            }`}>
              A comprehensive analysis of the ongoing energy crisis and pathways to a sustainable future
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button 
                onClick={handleShare}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isDarkMode
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                    : 'bg-amber-500 text-white hover:bg-amber-600'
                }`}
              >
                <Share2 className="h-5 w-5" />
                <span>Share Article</span>
              </button>
              <button 
                onClick={handleBookmark}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isBookmarked
                    ? isDarkMode
                      ? 'bg-yellow-400 text-blue-900'
                      : 'bg-amber-500 text-white'
                    : isDarkMode
                      ? 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                      : 'border-2 border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white'
                }`}
              >
                {isBookmarked ? <Bookmark className="h-5 w-5" /> : <BookmarkPlus className="h-5 w-5" />}
                <span>{isBookmarked ? 'Saved' : 'Save for Later'}</span>
              </button>
            </div>
          </section>

          {/* Table of Contents Toggle */}
          <button
            onClick={() => setShowTableOfContents(!showTableOfContents)}
            className={`fixed left-4 top-1/2 transform -translate-y-1/2 p-3 rounded-full shadow-lg ${
              isDarkMode ? 'bg-blue-800 text-yellow-400' : 'bg-amber-100 text-amber-700'
            }`}
          >
            <List className="h-6 w-6" />
          </button>

          {/* Floating Table of Contents */}
          {showTableOfContents && (
            <div className={`fixed left-16 top-1/2 transform -translate-y-1/2 p-4 rounded-lg shadow-xl ${
              isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
            }`}>
              <h3 className={`font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Table of Contents
              </h3>
              <ul className="space-y-2">
                {tableOfContents.map(({ id, title }) => (
                  <li key={id}>
                    <button
                      onClick={() => scrollToSection(id)}
                      className={`flex items-center text-left ${
                        activeSection === id
                          ? (isDarkMode ? 'text-yellow-400' : 'text-amber-700')
                          : 'text-gray-400'
                      } hover:text-yellow-400 transition-colors`}
                    >
                      <ChevronRight className={`h-4 w-4 mr-2 ${
                        activeSection === id ? 'opacity-100' : 'opacity-0'
                      }`} />
                      {title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Key Impacts Grid */}
          <section className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12`}>
            {keyImpacts.map((impact, index) => (
              <div 
                key={index}
                className={`p-6 rounded-lg transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-blue-800 hover:bg-blue-700' 
                    : 'bg-amber-100 hover:bg-amber-200'
                }`}
              >
                <div className={`flex items-center gap-4 mb-3`}>
                  <div className={`p-3 rounded-full ${
                    isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'
                  }`}>
                    {impact.icon}
                  </div>
                  <h3 className={`font-semibold ${
                    isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                  }`}>
                    {impact.title}
                  </h3>
                </div>
                <p className={isDarkMode ? 'text-gray-300' : 'text-amber-900'}>
                  {impact.description}
                </p>
              </div>
            ))}
          </section>

          {/* Article Meta */}
          <section className={`flex items-center justify-between mb-8 p-4 rounded-lg ${
            isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
          }`}>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Calendar className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <time dateTime={publishDate} className="text-sm">January 31, 2025</time>
              </div>
              <div className="flex items-center gap-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">8 min read</span>
              </div>
              <div className="flex items-center gap-2">
                <Tag className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">Energy Crisis</span>
              </div>
            </div>
          </section>

          {/* Load Shedding Stages Chart */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-3xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Load Shedding Stages Impact
            </h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={loadSheddingData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="stage" />
                  <YAxis label={{ value: 'Hours per Day', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Bar
                    dataKey="hours"
                    fill={isDarkMode ? '#fbbf24' : '#f59e0b'}
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </section>

          {/* Main Content with refs for scrolling */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <div className="prose prose-lg max-w-none">
              <div ref={(el) => sectionsRef.current['introduction'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Introduction
                </h2>
                <p className={`text-xl leading-relaxed mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  In 2025, South Africa continues to grapple with persistent load shedding—a symptom of systemic energy challenges rooted in decades of infrastructural neglect, coal dependency, and governance issues. Despite incremental progress in renewable energy and policy reforms, prolonged power cuts highlight the urgent need for comprehensive solutions.
                </p>
              </div>

              <div ref={(el) => sectionsRef.current['current-state'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Current State in 2025
                </h2>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Eskom, South Africa's state-owned utility, remains at the heart of the crisis. Aging coal plants and delays in renewable energy projects have left the grid fragile—with load shedding levels oscillating between Stages 3 and 5. While urban centers benefit from private solar investments, rural areas suffer the harshest outages.
                </p>
              </div>

              <div ref={(el) => sectionsRef.current['root-causes'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Root Causes
                </h2>
                <ul className={`list-disc list-inside space-y-2 mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  <li><strong>Aging Infrastructure:</strong> Over 60% of Eskom's generation fleet is more than 30 years old, leading to frequent breakdowns and maintenance backlogs.</li>
                  <li><strong>Coal Dependency:</strong> Approximately 65% of the country's energy is still derived from coal, causing shortages when plants underperform.</li>
                  <li><strong>Financial Crisis:</strong> With Eskom's debt exceeding R400 billion, there is little room for modernization while tariff hikes burden consumers.</li>
                  <li><strong>Governance Challenges:</strong> Corruption and bureaucratic delays have stalled necessary reforms, further eroding public trust.</li>
                  <li><strong>Renewable Energy Delays:</strong> Regulatory hurdles and grid capacity issues have slowed the integration of new solar and wind projects.</li>
                </ul>
              </div>

              <div ref={(el) => sectionsRef.current['socio-economic-impact'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Socio-Economic Impact
                </h2>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  The cascading effects of load shedding extend across every facet of society:
                </p>
                <ul className={`list-disc list-inside space-y-2 mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  <li><strong>Economy:</strong> Extended outages cost an estimated R500 million daily, impacting GDP growth and burdening key industries.</li>
                  <li><strong>Daily Life:</strong> Households face up to 8 hours without power, driving increased reliance on costly generators.</li>
                  <li><strong>Healthcare & Education:</strong> Critical services suffer—urban hospitals depend on backup systems while rural clinics and schools struggle with unreliable power.</li>
                  <li><strong>Social Unrest:</strong> Persistent power cuts have sparked protests in marginalized communities, demanding swift governmental action.</li>
                </ul>
              </div>

              <div ref={(el) => sectionsRef.current['responses'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Government and Private Sector Responses
                </h2>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  To address the crisis, several key measures have been implemented:
                </p>
                <ul className={`list-disc list-inside space-y-2 mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  <li><strong>Policy Reforms:</strong> Initiatives like the Electricity Regulation Amendment Bill aim to decentralize energy generation and promote rooftop solar.</li>
                  <li><strong>Private Investments:</strong> Major corporations are investing in renewable projects to reduce reliance on aging coal plants.</li>
                  <li><strong>International Partnerships:</strong> Programs such as the Just Energy Transition Partnership (JETP) are channeling billions into renewable energy, despite some disbursement delays.</li>
                </ul>
              </div>

              <div ref={(el) => sectionsRef.current['path-forward'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Path Forward
                </h2>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  Building a sustainable energy future for South Africa requires coordinated action across several fronts:
                </p>
                <ul className={`list-disc list-inside space-y-2 mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  <li><strong>Accelerate Renewables:</strong> Fast-track wind and solar projects to achieve a 30% renewable energy mix by 2030.</li>
                  <li><strong>Modernize Infrastructure:</strong> Invest in smart grids and upgrade aging facilities, including retrofitting coal plants with carbon capture technology.</li>
                  <li><strong>Financial Restructuring:</strong> Address Eskom's debt through equity partnerships and green bonds while implementing fair tariff reforms.</li>
                  <li><strong>Community Empowerment:</strong> Develop local microgrids and solar cooperatives to enhance energy access in underserved regions.</li>
                  <li><strong>Governance Overhaul:</strong> Strengthen anti-corruption measures and depoliticize management to restore operational efficiency.</li>
                </ul>
              </div>

              <div ref={(el) => sectionsRef.current['conclusion'] = el}>
                <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                  Conclusion
                </h2>
                <p className={`mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                  The load shedding crisis in South Africa in 2025 is a multifaceted challenge, rooted in long-standing infrastructure, financial, and governance issues. Yet, with concerted efforts from the government, private sector, and local communities, there is a clear pathway toward a stable and sustainable energy future.
                </p>
              </div>
            </div>
          </section>

          {/* Article Feedback Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Was this article helpful?
            </h2>
            <div className="flex gap-4">
              <button
                onClick={() => setFeedback(prev => ({ ...prev, helpful: prev.helpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-blue-700 hover:bg-blue-600'
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}
              >
                <ThumbsUp className="h-5 w-5" />
                <span>Yes ({feedback.helpful})</span>
              </button>
              <button
                onClick={() => setFeedback(prev => ({ ...prev, notHelpful: prev.notHelpful + 1 }))}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-blue-700 hover:bg-blue-600'
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}
              >
                <ThumbsDown className="h-5 w-5" />
                <span>No ({feedback.notHelpful})</span>
              </button>
            </div>
          </section>

          {/* Related Articles Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>
              Related Articles
            </h2>
            <div className="grid gap-6">
              {relatedArticles.map((article, index) => (
                <Link
                  key={index}
                  to={article.url}
                  className={`p-4 rounded-lg transition-all duration-300 ${
                    isDarkMode
                      ? 'bg-blue-700 hover:bg-blue-600'
                      : 'bg-amber-200 hover:bg-amber-300'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <h3 className={`font-semibold ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`}>
                      {article.title}
                    </h3>
                    <ArrowRight className={`h-5 w-5 ${
                      isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                    }`} />
                  </div>
                  <p className="text-sm mt-2">{article.date}</p>
                </Link>
              ))}
            </div>
          </section>

          {/* Scroll to Top Button */}
          {showScrollTop && (
            <button
              onClick={scrollToTop}
              className={`fixed bottom-8 right-8 p-3 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 ${
                isDarkMode
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}
            >
              <ChevronUp className="h-6 w-6" />
            </button>
          )}
        </article>
      </main>
    </div>
  );
};

export default LoadSheddingSouthAfrica2025; 