import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { Sun, Moon, Battery, Box, Home, Newspaper, Lightbulb, Star, PhoneCall, ChevronDown, ChevronUp, Zap, Calculator, X, DollarSign, Anchor, Cpu, Menu, ChevronLeft, ChevronRight, Smartphone, Wrench, Brush, Settings, TrendingUp, Share2, PlusCircle } from 'lucide-react';

const Sidebar = ({ isExpanded, setIsExpanded, closeMobileMenu, isMobileMenuOpen }) => {
  const { isDarkMode, toggleTheme } = useTheme();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isCalculatorsOpen, setIsCalculatorsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isTipsOpen, setIsTipsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const linkClass = (path) => `
    flex items-center p-2 rounded-lg
    ${isDarkMode ? 'text-blue-100 hover:bg-blue-700' : 'text-amber-100 hover:bg-amber-700'}
    ${location.pathname === path ? 'bg-yellow-400 text-blue-900 font-semibold' : ''}
    hover:text-yellow-400 transition-colors duration-200
    relative z-10
    ${!isExpanded ? 'justify-center' : ''}
  `;

  const iconClass = "w-6 h-6";

  const handleProductsClick = (e) => {
    e.preventDefault();
    if (isExpanded) {
      setIsProductsOpen(!isProductsOpen);
    } else {
      navigate('/product/all');
    }
  };

  const navigateToProducts = () => {
    navigate('/product/all');
    if (window.innerWidth < 1024) {
      closeMobileMenu();
    }
  };

  const handleCalculatorsClick = (e) => {
    e.preventDefault();
    if (isExpanded) {
      setIsCalculatorsOpen(!isCalculatorsOpen);
    } else {
      navigate('/calculators/savings');
    }
  };

  const handleServicesClick = (e) => {
    e.preventDefault();
    navigate('/services');
    if (isExpanded) {
      setIsServicesOpen(!isServicesOpen);
    }
    if (window.innerWidth < 1024) {
      closeMobileMenu();
    }
  };

  const handleTipsClick = (e) => {
    e.preventDefault();
    if (isExpanded) {
      setIsTipsOpen(!isTipsOpen);
    } else {
      navigate('/social-tips');
    }
    if (window.innerWidth < 1024) {
      closeMobileMenu();
    }
  };

  return (
    <div 
      className={`
        fixed lg:sticky top-0 left-0
        h-screen
        ${isDarkMode ? 'bg-blue-800' : 'bg-amber-900'} 
        text-white 
        overflow-y-auto 
        transition-all
        duration-300 
        ease-in-out 
        custom-scrollbar
        z-40
        ${isMobileMenuOpen 
          ? 'w-64 translate-x-0' 
          : 'w-0 lg:w-20 -translate-x-full lg:translate-x-0'
        }
        ${isExpanded ? 'lg:w-64' : 'lg:w-20'}
      `}
    >
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={`
          hidden lg:flex items-center justify-center
          absolute top-5 -right-3
          w-6 h-6
          rounded-full
          ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-800 hover:bg-amber-700'}
          text-yellow-400 hover:text-yellow-300
          transition-colors
          z-50
          shadow-lg
        `}
        title={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
      >
        {isExpanded ? <ChevronLeft size={14} /> : <ChevronRight size={14} />}
      </button>

      <div className="flex items-center justify-between p-4 border-b border-amber-300">
        <div className="flex items-center gap-2">
          {isExpanded ? (
            <div className="flex items-center gap-2">
              <h1 className="text-xl font-bold text-yellow-400">Solar Insight</h1>
              <Sun size={24} className="text-yellow-400" />
            </div>
          ) : (
            <Menu size={24} className="text-yellow-400" />
          )}
        </div>
        
        <button 
          onClick={toggleTheme} 
          className={`
            p-1.5 rounded-full 
            ${isDarkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-amber-800 hover:bg-amber-700'}
            transition-colors duration-200
          `}
          title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
        >
          {isDarkMode ? (
            <Moon size={16} className="text-yellow-400" />
          ) : (
            <Sun size={16} className="text-yellow-400" />
          )}
        </button>
        
        {isMobileMenuOpen && (
          <button 
            className="lg:hidden text-yellow-400 hover:text-yellow-300"
            onClick={closeMobileMenu}
          >
            <X size={24} />
          </button>
        )}
      </div>
      
      <nav className="mt-4 px-2 flex-1 overflow-y-auto">
        <ul className="space-y-1">
          <SidebarItem 
            to="/" 
            icon={<Home className={iconClass} />} 
            text="Home" 
            isExpanded={isExpanded} 
            linkClass={linkClass}
            closeMobileMenu={closeMobileMenu}
          />
          <SidebarDropdown 
            icon={<Box className={iconClass} />} 
            text="Products" 
            isExpanded={isExpanded} 
            isOpen={isProductsOpen} 
            onClick={handleProductsClick}
            onNavigate={navigateToProducts}
            linkClass={linkClass}
          >
            <SidebarItem 
              to="/product/panels" 
              icon={<Sun size={16} />} 
              text="Solar Panels" 
              isExpanded={isExpanded} 
              linkClass={linkClass}
              closeMobileMenu={closeMobileMenu}
            />
            <SidebarItem 
              to="/product/batteries" 
              icon={<Battery size={16} />} 
              text="Batteries" 
              isExpanded={isExpanded} 
              linkClass={linkClass}
              closeMobileMenu={closeMobileMenu}
            />
            <SidebarItem to="/product/inverters" icon={<Zap size={16} />} text="Inverters" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
            <SidebarItem to="/product/mounting" icon={<Anchor size={16} />} text="Mounting Systems" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
            <SidebarItem to="/product/controllers" icon={<Cpu size={16} />} text="Charge Controllers" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
          </SidebarDropdown>
          <SidebarDropdown
            icon={<Wrench className={iconClass} />}
            text="Services"
            isExpanded={isExpanded}
            isOpen={isServicesOpen}
            onClick={handleServicesClick}
            linkClass={linkClass}
          >
            <SidebarItem
              to="/services/cleaning"
              icon={<Brush size={16} />}
              text="Solar Cleaning"
              isExpanded={isExpanded}
              linkClass={linkClass}
              closeMobileMenu={closeMobileMenu}
            />
            <SidebarItem
              to="/services/repair"
              icon={<Wrench size={16} />}
              text="Solar Repair"
              isExpanded={isExpanded}
              linkClass={linkClass}
              closeMobileMenu={closeMobileMenu}
            />
            <SidebarItem
              to="/services/maintenance"
              icon={<Settings size={16} />}
              text="Solar Maintenance"
              isExpanded={isExpanded}
              linkClass={linkClass}
              closeMobileMenu={closeMobileMenu}
            />
          </SidebarDropdown>
          <SidebarDropdown 
            icon={<Calculator className={iconClass} />} 
            text="Calculators" 
            isExpanded={isExpanded} 
            isOpen={isCalculatorsOpen} 
            onClick={handleCalculatorsClick}
            linkClass={linkClass}
          >
            <SidebarItem to="/calculators/savings" icon={<DollarSign size={16} />} text="Savings Calculator" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
            <SidebarItem to="/calculators/cleaning" icon={<Brush size={16} />} text="Cleaning Calculator" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
            <SidebarItem to="/calculators/geyser" icon={<Zap size={16} />} text="Geyser Savings Calculator" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
            <SidebarItem 
              to="/calculators/tilt" 
              icon={<Smartphone size={16} />} 
              text="Panel Tilt Calculator" 
              isExpanded={isExpanded} 
              linkClass={linkClass} 
              closeMobileMenu={closeMobileMenu} 
            />
          </SidebarDropdown>
          <SidebarItem to="/news" icon={<Newspaper className={iconClass} />} text="News" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
          <SidebarItem to="/insights" icon={<Lightbulb className={iconClass} />} text="Insights" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
          <SidebarItem to="/reviews" icon={<Star className={iconClass} />} text="Reviews" isExpanded={isExpanded} linkClass={linkClass} closeMobileMenu={closeMobileMenu} />
        </ul>
      </nav>

      <div className="mt-auto p-4 border-t border-amber-300 bg-inherit">
        <SidebarDropdown 
          icon={<Share2 className={iconClass} />} 
          text="Social Tips" 
          isExpanded={isExpanded} 
          isOpen={isTipsOpen} 
          onClick={handleTipsClick}
          linkClass={linkClass}
        >
          <SidebarItem 
            to="/social-tips" 
            icon={<Sun size={16} />} 
            text="View Tips" 
            isExpanded={isExpanded} 
            linkClass={linkClass}
            closeMobileMenu={closeMobileMenu}
          />
          <SidebarItem 
            to="/social-tips/add" 
            icon={<PlusCircle size={16} />} 
            text="Create Tip" 
            isExpanded={isExpanded} 
            linkClass={linkClass}
            closeMobileMenu={closeMobileMenu}
          />
          <SidebarItem 
            to="/social-tips/manage" 
            icon={<Settings size={16} />} 
            text="Manage Tips" 
            isExpanded={isExpanded} 
            linkClass={linkClass}
            closeMobileMenu={closeMobileMenu}
          />
        </SidebarDropdown>
        <SidebarItem 
          to="/contact" 
          icon={<PhoneCall className={iconClass} />} 
          text="Contact Us" 
          isExpanded={isExpanded} 
          linkClass={linkClass}
          closeMobileMenu={closeMobileMenu}
        />
      </div>
    </div>
  );
};

const SidebarItem = ({ to, icon, text, isExpanded, linkClass, closeMobileMenu }) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(to);
    if (window.innerWidth < 1024) {
      closeMobileMenu();
    }
  };

  return (
    <li>
      <button 
        onClick={handleClick}
        className={`
          ${linkClass(to)} 
          py-2 
          w-full 
          text-left 
          flex 
          items-center
          min-w-0
          transition-colors
          duration-200
          ${!isExpanded ? 'justify-center px-0' : ''}
        `}
      >
        <span className="flex-shrink-0" title={!isExpanded ? text : ''}>{icon}</span>
        {isExpanded && (
          <span className="ml-3 truncate flex-1">{text}</span>
        )}
      </button>
    </li>
  );
};

const SidebarDropdown = ({ icon, text, isExpanded, isOpen, onClick, linkClass, children }) => (
  <li className="relative">
    <button 
      onClick={onClick} 
      className={`
        w-full py-2 
        ${linkClass("")} 
        flex items-center 
        ${isExpanded ? 'justify-between' : 'justify-center'}
      `}
    >
      <div className={`flex items-center min-w-0 ${!isExpanded ? 'justify-center' : ''}`}>
        <span className="flex-shrink-0" title={!isExpanded ? text : ''}>{icon}</span>
        {isExpanded && <span className="ml-3 truncate">{text}</span>}
      </div>
      {isExpanded && (
        <span className="flex-shrink-0 ml-2">
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </span>
      )}
    </button>

    {isExpanded && (
      <div className={`
        absolute left-0 w-full bg-inherit
        transition-all duration-300 ease-in-out
        ${isOpen ? 'relative opacity-100 visible' : 'h-0 opacity-0 invisible'}
      `}>
        <ul className={`
          py-1 ml-4 pl-4 
          border-l border-amber-600/30 
          space-y-1
          ${isOpen ? 'mt-1' : ''}
        `}>
          {children}
        </ul>
      </div>
    )}
  </li>
);

export default Sidebar;