import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './components/auth/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/layout/Layout';
import ScrollToTop from './components/layout/ScrollToTop';
import Navbar from './components/navigation/Navbar';

// Pages
import Home from './components/Home';
import Dashboard from './components/dashboard/Dashboard';
import LoadSheddingPage from './components/loadShedding/LoadSheddingPage';
import ProductList from './components/products/ProductList';
import ProductDetail from './components/products/ProductDetail';
import Login from './components/auth/Login';
import PrivateRoute from './components/auth/PrivateRoute';
import SavingsCalculator from './components/calculator/SavingsCalculator';
import PanelSizeCalculator from './components/calculator/PanelSizeCalculator';
import BatteryCapacityCalculator from './components/calculator/BatteryCapacityCalculator';
import CleaningCalculator from './components/calculator/CleaningCalculator';
import SolarGeyserCalculator from './components/calculator/SolarGeyserCalculator';
import SolarFinancingCalculator from './components/calculator/SolarFinancingCalculator';
import InsightPage from './components/InsightPage';
import NewsPage from './components/NewsPage';
import ReviewsPage from './components/ReviewsPage';
import ProductPage from './components/ProductPage';
import SolarPanelPage from './components/products/SolarPanelPage';
import BatteryPage from './components/products/BatteryPage';
import InverterPage from './components/products/InverterPage';
import MountingSystemPage from './components/products/MountingSystemPage';
import ChargeControllerPage from './components/products/ChargeControllerPage';
import ContactPage from './components/ContactPage';
import ThankYouPage from './components/ThankYouPage';
import SearchResults from './components/SearchResults';
import SolarTiltGauge from './components/calculators/SolarTiltGauge';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';

// Service Pages
import SolarServicePage from './components/services/SolarServicePage';
import SolarCleaningPage from './components/services/SolarCleaningPage';
import SolarRepairPage from './components/services/SolarRepairPage';
import SolarMaintenancePage from './components/services/SolarMaintenancePage';

// Social Tips
import SocialTipsList from './components/social-tips/SocialTipsList';
import SocialTipDetail from './components/social-tips/SocialTipDetail';
import AddTip from './components/social-tips/AddTip';
import ManageTips from './components/social-tips/ManageTips';
import PerovskiteSolarBreakthrough from './components/articles/PerovskiteSolarBreakthrough';
import LoadSheddingSouthAfrica2025 from './components/articles/LoadSheddingSouthAfrica2025';

// Loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">Something went wrong</h1>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!user) {
    return <Navigate to="/signin" />;
  }
  
  return children;
};

function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <ThemeProvider>
            <AuthProvider>
              <ScrollToTop />
              <Navbar />
              <Suspense fallback={<LoadingSpinner />}>
                <Layout>
                  <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/load-shedding" element={<LoadSheddingPage />} />
                    <Route path="/product/all" element={<ProductPage />} />
                    <Route path="/product/panels" element={<SolarPanelPage />} />
                    <Route path="/product/batteries" element={<BatteryPage />} />
                    <Route path="/product/inverters" element={<InverterPage />} />
                    <Route path="/product/mounting" element={<MountingSystemPage />} />
                    <Route path="/product/controllers" element={<ChargeControllerPage />} />
                    <Route path="/products/:id" element={<ProductDetail />} />
                    <Route path="/calculators/savings" element={<SavingsCalculator />} />
                    <Route path="/calculators/financing" element={<SolarFinancingCalculator />} />
                    <Route path="/calculators/panel" element={<PanelSizeCalculator />} />
                    <Route path="/calculators/battery" element={<BatteryCapacityCalculator />} />
                    <Route path="/calculators/cleaning" element={<CleaningCalculator />} />
                    <Route path="/calculators/geyser" element={<SolarGeyserCalculator />} />
                    <Route path="/calculators/tilt" element={<SolarTiltGauge />} />
                    <Route path="/insights" element={<InsightPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/news/perovskite-solar-breakthrough-2025" element={<PerovskiteSolarBreakthrough />} />
                    <Route path="/news/load-shedding-south-africa-2025" element={<LoadSheddingSouthAfrica2025 />} />
                    <Route path="/reviews" element={<ReviewsPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/thank-you" element={<ThankYouPage />} />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />

                    {/* Service Routes */}
                    <Route path="/services" element={<SolarServicePage />} />
                    <Route path="/services/cleaning" element={<SolarCleaningPage />} />
                    <Route path="/services/repair" element={<SolarRepairPage />} />
                    <Route path="/services/maintenance" element={<SolarMaintenancePage />} />

                    {/* Protected Routes */}
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/loadshedding"
                      element={
                        <ProtectedRoute>
                          <LoadSheddingPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/battery"
                      element={
                        <ProtectedRoute>
                          <BatteryPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/solar-tilt"
                      element={
                        <ProtectedRoute>
                          <SolarTiltGauge />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/savings-calculator"
                      element={
                        <ProtectedRoute>
                          <SavingsCalculator />
                        </ProtectedRoute>
                      }
                    />

                    {/* Social Tips routes */}
                    <Route path="/social-tips" element={<SocialTipsList />} />
                    <Route path="/social-tips/:category/:tipId" element={<SocialTipDetail />} />

                    {/* Catch all route */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Layout>
              </Suspense>
            </AuthProvider>
          </ThemeProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;