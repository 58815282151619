import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '../../contexts/ThemeContext';
import { Share2, Bookmark, ChevronRight, Download, BarChart2, ArrowRight, Zap, Shield, Calendar, Clock, Tag, Share, BookmarkPlus, ChevronUp } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Cell } from 'recharts';

const PerovskiteSolarBreakthrough = () => {
  const { isDarkMode } = useTheme();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const publishDate = "2025-01-29T09:00:00Z";
  const siteUrl = 'https://solarinsight.co.za';
  const articlePath = '/news/perovskite-solar-breakthrough-2025';
  const fullUrl = `${siteUrl}${articlePath}`;

  // Handle scroll to top
  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Record-Breaking Perovskite Solar Cells',
          text: 'New breakthrough in solar technology achieving 29.15% efficiency',
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      // Fallback copy to clipboard
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
    // Add animation or notification here
    const message = isBookmarked ? 'Article removed from bookmarks' : 'Article saved to bookmarks';
    // You could implement a toast notification here
  };

  const efficiencyData = [
    { type: 'New Perovskite', efficiency: 29.15, color: '#EAB308' },
    { type: 'Silicon', efficiency: 26.7, color: '#78716C' },
    { type: 'Thin-Film', efficiency: 23.4, color: '#78716C' },
    { type: 'Organic', efficiency: 18.2, color: '#78716C' },
  ];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "TechArticle",
    "headline": "Perovskite Solar Cells Break Efficiency Record: The Future of Solar Technology",
    "datePublished": publishDate,
    "dateModified": publishDate,
    "author": {
      "@type": "Organization",
      "name": "Solar Insight"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Solar Insight",
      "logo": {
        "@type": "ImageObject",
        "url": `${siteUrl}/logo.png`
      }
    },
    "description": "Comprehensive analysis of breakthrough perovskite solar cell technology achieving 29.15% efficiency. Explore the future of renewable energy with detailed insights into advantages, challenges, and applications.",
    "keywords": "perovskite solar cells, solar efficiency, renewable energy, solar technology, perovskite technology, solar innovation, sustainable energy",
    "articleSection": "Solar Technology",
    "image": `${siteUrl}/images/perovskite-breakthrough.jpg`,
    "url": fullUrl,
    "mainEntityOfPage": fullUrl
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-gray-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Perovskite Solar Cells Break Efficiency Record: The Future of Solar Technology | Solar Insight</title>
        <meta name="description" content="Discover how perovskite solar cells achieve 29.15% efficiency, revolutionizing solar energy with higher performance and lower costs. Learn about the latest breakthroughs in renewable energy technology." />
        <meta name="keywords" content="perovskite solar cells, solar efficiency record, renewable energy breakthrough, solar technology innovation, perovskite technology, solar cell efficiency, renewable energy future, sustainable energy solutions" />
        
        <meta property="og:title" content="Perovskite Solar Cells Break Efficiency Record: The Future of Solar Technology" />
        <meta property="og:description" content="Groundbreaking research reveals new perovskite solar cell technology achieving 29.15% efficiency, revolutionizing the future of renewable energy." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:image" content={`${siteUrl}/images/perovskite-breakthrough.jpg`} />
        
        <link rel="canonical" href={fullUrl} />
        
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <section className={`rounded-xl shadow-2xl mb-12 p-8 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-800 to-blue-900' 
              : 'bg-gradient-to-br from-amber-100 to-amber-200'
          }`}>
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 text-center ${
              isDarkMode 
                ? 'text-yellow-400' 
                : 'text-amber-700'
            }`}>
              New Perovskite Solar Cells Break Efficiency Record
            </h1>
            <p className={`text-xl mb-8 text-center ${
              isDarkMode ? 'text-yellow-100' : 'text-amber-800'
            }`}>
              A breakthrough in solar technology achieving 29.15% efficiency
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button 
                onClick={handleShare}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isDarkMode
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                    : 'bg-amber-500 text-white hover:bg-amber-600'
                }`}
              >
                <Share className="h-5 w-5" />
                <span>Share Article</span>
              </button>
              <button 
                onClick={handleBookmark}
                className={`flex items-center justify-center gap-2 px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 font-semibold shadow-lg ${
                  isBookmarked
                    ? isDarkMode
                      ? 'bg-yellow-400 text-blue-900'
                      : 'bg-amber-500 text-white'
                    : isDarkMode
                      ? 'border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                      : 'border-2 border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white'
                }`}
              >
                {isBookmarked ? <Bookmark className="h-5 w-5" /> : <BookmarkPlus className="h-5 w-5" />}
                <span>{isBookmarked ? 'Saved' : 'Save for Later'}</span>
              </button>
            </div>
          </section>

          {/* Article Meta */}
          <section className={`flex items-center justify-between mb-8 p-4 rounded-lg ${
            isDarkMode ? 'bg-blue-800' : 'bg-amber-100'
          }`}>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Calendar className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <time dateTime={publishDate} className="text-sm">January 29, 2025</time>
              </div>
              <div className="flex items-center gap-2">
                <Clock className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">5 min read</span>
              </div>
              <div className="flex items-center gap-2">
                <Tag className={`h-5 w-5 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
                <span className="text-sm">Solar Technology</span>
              </div>
            </div>
          </section>

          {/* Article Content */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <div className="prose prose-lg max-w-none">
              <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Perovskite Solar Cells
              </h2>
              
              <p className={`text-xl leading-relaxed mb-8 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                Solar power is truly revolutionary. As the world fights climate change, the push for cleaner, more efficient energy 
                has never been stronger. In a groundbreaking development, researchers have achieved a remarkable 29.15% efficiency 
                with new perovskite solar cells, setting a new world record for this promising technology.
              </p>

              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Understanding the Technology
              </h2>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                Perovskite solar cells represent a revolutionary approach to solar energy harvesting. Their unique crystal structure 
                blends organic and inorganic materials into a three-dimensional lattice, allowing for exceptional light absorption 
                and energy conversion capabilities. First developed in the late 2000s, their efficiency has skyrocketed from a mere 
                few percent to now rivaling and even surpassing traditional silicon panels.
              </p>
              <p className={`mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                What makes them truly special is their adaptability - scientists can fine-tune their composition to optimize 
                performance for specific applications. This flexibility, combined with their high efficiency and lower production 
                costs, makes perovskites a game-changing technology in the solar industry.
              </p>

              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Perovskite Solar Cells Advantages Over Traditional Solar Cells
              </h2>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                While silicon has been the industry standard for decades, perovskite technology offers several compelling advantages:
              </p>
              <ul className={`list-disc list-inside space-y-2 mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                <li>Higher efficiency potential - reaching up to 29.15% in recent tests</li>
                <li>Lower production costs due to simpler manufacturing processes</li>
                <li>Flexible and lightweight design possibilities</li>
                <li>Potential for innovative applications like solar windows and wearable tech</li>
                <li>Simpler manufacturing process, similar to inkjet printing</li>
              </ul>

              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Current Challenges and Solutions with Perovskite Solar Cells
              </h2>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                Despite their promising potential, perovskite solar cells face several challenges that researchers are actively addressing:
              </p>
              <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                <div>
                  <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Current Challenges
                  </h3>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Stability and durability concerns</li>
                    <li>Sensitivity to environmental factors</li>
                    <li>Presence of lead in some compositions</li>
                    <li>Scaling up production processes</li>
                  </ul>
                </div>
                <div>
                  <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Ongoing Solutions
                  </h3>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Development of lead-free alternatives</li>
                    <li>Enhanced encapsulation techniques</li>
                    <li>Improved manufacturing processes</li>
                    <li>Integration with existing technologies</li>
                  </ul>
                </div>
              </div>

              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Innovative Applications
              </h2>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                The versatility of perovskite technology opens up exciting new possibilities:
              </p>
              <ul className={`list-disc list-inside space-y-2 mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                <li>Building-integrated photovoltaics (BIPV)</li>
                <li>Transparent solar windows</li>
                <li>Flexible solar panels for curved surfaces</li>
                <li>Portable and wearable solar devices</li>
                <li>Tandem cells combining perovskite with silicon</li>
              </ul>

              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
                Environmental and Economic Impact
              </h2>
              <p className={`mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                The widespread adoption of perovskite solar technology could have significant environmental and economic benefits:
              </p>
              <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
                <div>
                  <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Environmental Benefits
                  </h3>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Reduced carbon emissions</li>
                    <li>Lower energy production footprint</li>
                    <li>Decreased material waste</li>
                    <li>Sustainable energy production</li>
                  </ul>
                </div>
                <div>
                  <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-yellow-300' : 'text-amber-600'}`}>
                    Economic Advantages
                  </h3>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Lower production costs</li>
                    <li>Job creation in renewable energy</li>
                    <li>Increased energy accessibility</li>
                    <li>Market growth opportunities</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Key Achievements Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-700'}`}>
              Key Achievements
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                {
                  icon: <BarChart2 className="h-6 w-6" />,
                  title: "Record-breaking Efficiency",
                  description: "Achieved 29.15% efficiency, setting a new world record"
                },
                {
                  icon: <Shield className="h-6 w-6" />,
                  title: "Enhanced Stability",
                  description: "Improved durability and longevity of solar cells"
                },
                {
                  icon: <Zap className="h-6 w-6" />,
                  title: "Cost-Effective",
                  description: "Reduced manufacturing costs while maintaining performance"
                },
                {
                  icon: <ArrowRight className="h-6 w-6" />,
                  title: "Scalable Solution",
                  description: "Ready for commercial production and deployment"
                }
              ].map((achievement, index) => (
                <div key={index} className={`p-6 rounded-lg transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-blue-700 hover:bg-blue-600' 
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}>
                  <div className="flex items-center space-x-4">
                    <div className={`flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full ${
                      isDarkMode 
                        ? 'bg-yellow-400 text-blue-900' 
                        : 'bg-amber-500 text-white'
                    }`}>
                      {achievement.icon}
                    </div>
                    <div>
                      <h3 className={`text-lg font-semibold ${
                        isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                      }`}>
                        {achievement.title}
                      </h3>
                      <p className={isDarkMode ? 'text-gray-100' : 'text-amber-900'}>
                        {achievement.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Efficiency Chart Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-4xl font-bold mb-8 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Efficiency Comparison</h2>

            {/* Chart Container */}
            <div className="w-full h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={efficiencyData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 40,
                  }}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke={isDarkMode ? '#1e40af' : '#fcd34d'} 
                    opacity={0.3}
                  />
                  <XAxis 
                    dataKey="type" 
                    angle={-15} 
                    textAnchor="end" 
                    height={60}
                    tick={{ 
                      fill: isDarkMode ? '#f3f4f6' : '#78350f',
                      fontSize: 14 
                    }}
                  />
                  <YAxis 
                    label={{ 
                      value: 'Efficiency (%)', 
                      angle: -90, 
                      position: 'insideLeft',
                      fill: isDarkMode ? '#f3f4f6' : '#78350f'
                    }}
                    tick={{ 
                      fill: isDarkMode ? '#f3f4f6' : '#78350f',
                      fontSize: 14 
                    }}
                    domain={[0, 30]}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: isDarkMode ? '#1e3a8a' : '#fef3c7',
                      border: 'none',
                      borderRadius: '8px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                    labelStyle={{
                      color: isDarkMode ? '#f3f4f6' : '#78350f',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    itemStyle={{
                      color: isDarkMode ? '#fcd34d' : '#92400e',
                    }}
                    formatter={(value) => [`${value}%`, 'Efficiency']}
                  />
                  <Bar 
                    dataKey="efficiency" 
                    radius={[4, 4, 0, 0]}
                  >
                    {efficiencyData.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`}
                        fill={entry.type === 'New Perovskite' 
                          ? (isDarkMode ? '#fbbf24' : '#f59e0b')
                          : (isDarkMode ? '#3b82f6' : '#fcd34d')
                        }
                      />
                    ))}
                    <LabelList 
                      dataKey="efficiency" 
                      position="top" 
                      formatter={(value) => `${value}%`}
                      fill={isDarkMode ? '#f3f4f6' : '#78350f'}
                      fontSize={14}
                      fontWeight="bold"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <p className={`text-center text-sm mt-8 ${
              isDarkMode ? 'text-gray-300' : 'text-amber-700'
            }`}>
              Comparison of different solar cell technologies' efficiency rates
            </p>
          </section>

          {/* Technical Details Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Technical Specifications</h2>
            <div className="overflow-x-auto">
              <table className="w-full">
                <tbody className={`divide-y ${isDarkMode ? 'divide-blue-700' : 'divide-amber-200'}`}>
                  {[
                    { label: "Efficiency Rate", value: "29.15%" },
                    { label: "Cell Stability", value: "1000+ hours" },
                    { label: "Manufacturing Cost", value: "40% lower than traditional cells" },
                    { label: "Scale Potential", value: "Highly scalable" }
                  ].map((spec, index) => (
                    <tr key={index} className={`hover:bg-opacity-50 transition-colors duration-200 ${
                      isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-200'
                    }`}>
                      <td className={`py-4 px-4 ${
                        isDarkMode ? 'text-gray-100' : 'text-amber-900'
                      }`}>{spec.label}</td>
                      <td className={`py-4 px-4 font-semibold ${
                        isDarkMode ? 'text-yellow-400' : 'text-amber-600'
                      }`}>{spec.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/* Research Methodology */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Research Methodology</h2>
            <div className={`space-y-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
              <p>
                The research team employed advanced fabrication techniques and novel materials to achieve this breakthrough. 
                Key aspects of the methodology include:
              </p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Advanced material synthesis processes</li>
                <li>Precise control of crystal formation</li>
                <li>Innovative interface engineering</li>
                <li>Rigorous testing under various conditions</li>
              </ul>
            </div>
          </section>

          {/* FAQs Section */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "What makes perovskite solar cells unique?",
                  answer: "Perovskite solar cells combine high efficiency with low production costs and flexible applications. Their unique crystal structure allows for excellent light absorption and energy conversion properties."
                },
                {
                  question: "How does this efficiency compare to traditional solar cells?",
                  answer: "The new efficiency record of 29.15% surpasses most commercial silicon solar cells, which typically achieve 20-22% efficiency. This represents a significant improvement in solar technology."
                },
                {
                  question: "When will this technology be commercially available?",
                  answer: "While the research shows promising results, commercialization is expected within 2-3 years. The team is currently working on scaling up production while maintaining the high efficiency levels."
                },
                {
                  question: "What are the environmental implications?",
                  answer: "This technology could significantly reduce the environmental impact of solar panel production while increasing renewable energy adoption due to improved efficiency and lower costs."
                }
              ].map((faq, index) => (
                <div key={index} className={`p-6 rounded-lg transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-blue-700 hover:bg-blue-600' 
                    : 'bg-amber-200 hover:bg-amber-300'
                }`}>
                  <h3 className={`text-lg font-semibold mb-2 ${
                    isDarkMode ? 'text-yellow-400' : 'text-amber-700'
                  }`}>{faq.question}</h3>
                  <p className={isDarkMode ? 'text-gray-100' : 'text-amber-900'}>{faq.answer}</p>
                </div>
              ))}
            </div>
          </section>

          {/* Future Implications */}
          <section className={`p-8 rounded-lg shadow-xl mb-12 ${
            isDarkMode ? 'bg-blue-800/50' : 'bg-amber-100/50'
          }`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Future Implications</h2>
            <div className={`space-y-4 ${isDarkMode ? 'text-gray-100' : 'text-amber-900'}`}>
              <p>
                The breakthrough in perovskite solar cell efficiency opens new possibilities for renewable energy adoption. 
                Key future developments may include:
              </p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Integration with existing solar infrastructure</li>
                <li>Development of hybrid solar technologies</li>
                <li>Expansion into new applications and markets</li>
                <li>Further efficiency improvements through ongoing research</li>
              </ul>
            </div>
          </section>

          {/* Call to Action Section */}
          <section className={`text-center p-8 rounded-lg shadow-xl ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-800 to-blue-900' 
              : 'bg-gradient-to-br from-amber-100 to-amber-200'
          }`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-yellow-400' : 'text-amber-700'
            }`}>Stay Updated on Solar Technology</h2>
            <p className={`mb-8 max-w-2xl mx-auto ${
              isDarkMode ? 'text-gray-100' : 'text-amber-900'
            }`}>
              Subscribe to our newsletter for the latest breakthroughs in solar technology
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4 px-4">
              <button className={`px-8 py-3 rounded-lg transition-colors duration-300 font-semibold ${
                isDarkMode
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}>
                Subscribe Now
              </button>
              <button className={`px-8 py-3 rounded-lg border-2 transition-colors duration-300 font-semibold ${
                isDarkMode
                  ? 'border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-blue-900'
                  : 'border-amber-500 text-amber-500 hover:bg-amber-500 hover:text-white'
              }`}>
                Learn More
              </button>
            </div>
          </section>

          {/* Scroll to Top Button */}
          {showScrollTop && (
            <button
              onClick={scrollToTop}
              className={`fixed bottom-8 right-8 p-3 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 ${
                isDarkMode
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300'
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              }`}
            >
              <ChevronUp className="h-6 w-6" />
            </button>
          )}
        </article>
      </main>
    </div>
  );
};

export default PerovskiteSolarBreakthrough; 